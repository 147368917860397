/* ManagePrompts.css */

.manage-prompts-container {
  max-width: 900px;
  margin: 20px auto;
  padding: 20px;
  color: #f0f0f0;
  background-color: #282c34; /* Match app background */
  border-radius: 8px;
}

.manage-prompts-container h2,
.manage-prompts-container h3 {
  text-align: center;
  color: #a0e9ff;
  margin-bottom: 20px;
}

/* Form Styling */
.prompt-form {
  background-color: #3a3a3a;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 30px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #ddd;
}

.form-group input[type="text"],
.form-group textarea {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #555;
  background-color: #333;
  color: #f0f0f0;
  font-size: 1rem;
  box-sizing: border-box;
}

.form-group textarea {
  min-height: 80px;
  resize: vertical;
}

.form-group small {
    display: block;
    margin-top: 5px;
    color: #aaa;
    font-size: 0.9em;
}

.form-actions {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

/* Basic Button Styles (can be refined) */
.btn {
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.2s ease;
}

.btn-primary {
  background-color: #007bff;
  color: white;
}
.btn-primary:hover {
  background-color: #0056b3;
}

.btn-secondary {
  background-color: #6c757d;
  color: white;
}
.btn-secondary:hover {
  background-color: #5a6268;
}

.btn-edit {
  background-color: #ffc107;
  color: #212529;
}
.btn-edit:hover {
  background-color: #e0a800;
}

.btn-delete {
  background-color: #dc3545;
  color: white;
}
.btn-delete:hover {
  background-color: #c82333;
}


/* List Styling */
.prompts-list {
  list-style: none;
  padding: 0;
}

.prompts-list li {
  background-color: #3a3a3a;
  margin-bottom: 15px;
  padding: 15px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 15px;
}

.prompt-details {
  flex-grow: 1;
}

.prompt-details strong {
  color: #a0e9ff;
  font-size: 1.1em;
}

.prompt-details p {
  margin: 5px 0;
  color: #ccc;
}
.prompt-details code {
    background-color: #282c34;
    padding: 2px 5px;
    border-radius: 3px;
    font-size: 0.95em;
}

.prompt-details small {
  color: #aaa;
  font-size: 0.85em;
}

.prompt-actions {
  display: flex;
  flex-direction: column; /* Stack buttons vertically */
  gap: 8px;
  flex-shrink: 0; /* Prevent buttons from shrinking */
}

.error-message {
    color: #dc3545;
    font-weight: bold;
    text-align: center;
}

hr {
    border: none;
    border-top: 1px solid #555;
    margin: 30px 0;
}
