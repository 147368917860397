.turtle-container {
  font-family: sans-serif;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #e0e0e0; /* Made darker */
  max-width: 900px; /* Adjust as needed */
  margin: 0 auto; /* Removed vertical margin */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  /* height: 100vh; /* Fill viewport height - REMOVED */
  display: flex; /* Arrange children vertically */
  flex-direction: column;
  box-sizing: border-box; /* Include padding/border in height */
}

.turtle-header {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.turtle-main {
  display: flex;
  gap: 20px; /* Space between canvas and history */
  margin-bottom: 20px;
}

/* Styles for the wrapper div */
.turtle-canvas {
  width: 600px; /* Match canvas attribute */
  height: 400px; /* Match canvas attribute */
  border: 1px solid #aaa;
  background-color: #f0f0f0; /* Default background */
  /* display: block; /* Wrapper doesn't need display: block */
  flex-shrink: 0; /* Prevent wrapper from shrinking in flex layout */
  box-sizing: border-box; /* Include border in size */
}

/* Styles for the actual canvas element inside the wrapper */
.turtle-canvas canvas {
  display: block; /* Prevents extra space below canvas */
  /* Optional: Ensure canvas fills wrapper if needed, but attributes should handle it */
  /* width: 100%; */
  /* height: 100%; */
}

.turtle-history {
  flex-basis: 250px; /* Width of the history panel */
  border: 1px solid #ddd;
  padding: 10px;
  background-color: #f0f0f0; /* Matched canvas background */
  height: 400px; /* Match canvas height */
  overflow-y: auto; /* Scroll if history gets long */
  font-size: 0.9em;
  min-height: 100px; /* Add a minimum height */
}

.turtle-history h3 {
  margin-top: 0;
  color: #555;
  border-bottom: 1px solid #eee;
  padding-bottom: 5px;
}

.turtle-history ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.turtle-history li {
  padding: 3px 0;
  border-bottom: 1px dashed #eee;
  word-wrap: break-word; /* Wrap long commands */
  color: #333; /* Added dark text color for readability */
}

.turtle-form {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
}

.turtle-input {
  flex-grow: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.turtle-button,
.save-button {
  padding: 8px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  transition: background-color 0.2s;
}

.turtle-button:hover,
.save-button:hover {
  background-color: #0056b3;
}

.save-button {
  background-color: #28a745;
}

.save-button:hover {
  background-color: #218838;
}

.turtle-error {
  color: #dc3545;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 15px;
  white-space: pre-wrap; /* Preserve line breaks in errors */
}

.turtle-legend {
  margin-top: 20px;
  padding: 15px;
  background-color: #e9ecef; /* Reverted to lighter background */
  border-radius: 4px;
  font-size: 0.9em;
  overflow-y: auto; /* Allow internal scrolling */
  flex-shrink: 1; /* Allow legend to shrink if needed */
}

.turtle-legend h3 {
  margin-top: 0;
  color: #495057;
}

.turtle-legend ul {
  list-style: none;
  padding: 0;
  columns: 2; /* Re-enabled two columns */
  gap: 20px; /* Keep gap for spacing if needed, or remove */
}

.turtle-legend li {
  margin-bottom: 5px;
  color: #444; /* Dark grey text for better contrast on legend bg */
}

.turtle-legend code {
  background-color: #f8f8f8; /* Subtle background for code */
  padding: 2px 4px;
  border-radius: 3px;
  font-family: monospace;
  color: #222; /* Darker text for code elements */
}
