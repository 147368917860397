/* Prompter.css */

.prompter-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  color: #f0f0f0; /* Light text on dark background */
  font-family: sans-serif; /* Add a default font */
}

.prompter-container h1 {
  text-align: center;
  margin-bottom: 20px;
  color: #f0f0f0;
  font-size: 2em; /* Larger heading */
}

.description {
  text-align: center;
  margin-bottom: 30px;
  color: #ccc;
  font-style: italic; /* Italicize description */
}

/* Styles for Query Input */
.query-input {
  margin-bottom: 30px;
}

.query-input label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
}

.query-input textarea { /* Changed from input to textarea */
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #555;
  background-color: #333;
  color: #f0f0f0;
  font-size: 1rem;
  min-height: 60px; /* Give textarea some default height */
  box-sizing: border-box; /* Include padding in width */
}

/* --- NEW: Styles for Enhancement Inputs --- */
.enhancement-inputs {
  /* Using inline styles for flex layout in Prompter.js */
  /* Add padding/margin if needed */
  padding: 10px 0;
  border-bottom: 1px solid #444; /* Separator line */
  margin-bottom: 25px;
}

.input-group {
  /* Using inline styles for flex sizing in Prompter.js */
  margin-bottom: 10px; /* Space below each group if they wrap */
}

.input-group label {
  display: block;
  margin-bottom: 6px;
  font-weight: bold;
  color: #ddd;
}

.input-group input[type="text"],
.input-group select {
  width: 100%;
  padding: 8px 10px;
  border-radius: 4px;
  border: 1px solid #555;
  background-color: #333;
  color: #f0f0f0;
  font-size: 0.95rem;
  box-sizing: border-box;
}

.input-group input[type="checkbox"] {
  width: auto; /* Don't force checkbox to full width */
  margin-right: 8px;
  accent-color: #a0e9ff; /* Style checkbox color */
}

.input-group input:disabled,
.input-group select:disabled {
  background-color: #444; /* Darker background when disabled */
  cursor: not-allowed;
  opacity: 0.7;
}
/* --- END NEW Styles --- */


/* Styles for Properties Container (Grid) */
.properties-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Responsive grid */
  gap: 25px; /* Space between grid items */
  margin-bottom: 30px;
}

/* Styles for Slider Group */
.slider-group {
  background-color: #3a3a3a; /* Slightly lighter background for grouping */
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.slider-group label {
  display: block; /* Label on its own line */
  margin-bottom: 10px; /* Space between label and slider */
  font-weight: bold;
  color: #ddd; /* Lighter label color */
}

.slider-group .slider-value {
  font-weight: normal; /* Normal weight for the value */
  color: #a0e9ff; /* Highlight color for the selected value */
  margin-left: 8px; /* Space between label text and value */
  display: inline-block; /* Keep it inline but allow margin */
}

/* Styles for the Slider Input */
.slider {
  width: 100%; /* Slider takes full width of its container */
  cursor: pointer;
  height: 8px; /* Adjust height */
  background: #555; /* Track background */
  border-radius: 5px;
  outline: none;
  appearance: none; /* Override default look */
  -webkit-appearance: none; /* Override default look for WebKit */
}

/* Customizing Slider Thumb (the draggable part) */
.slider::-webkit-slider-thumb {
  appearance: none;
  -webkit-appearance: none;
  width: 28px; /* Increased thumb size */
  height: 28px; /* Increased thumb size */
  background: #a0e9ff; /* Thumb color */
  border-radius: 50%; /* Make it round */
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 28px; /* Increased thumb size */
  height: 28px; /* Increased thumb size */
  background: #a0e9ff;
  border-radius: 50%;
  cursor: pointer;
  border: none; /* Remove default border in Firefox */
}

/* Styles for Action Row (Container for Button and Dropdown) */
/* Centering and gap handled by inline styles in Prompter.js */
.action-row {
  /* Ensure the container allows flex items to grow */
  width: 100%; /* Or max-width as appropriate */
  max-width: 600px; /* Example max-width for the centered row */
  margin-left: auto;
  margin-right: auto;
}

/* Styles for Generate Button */
.generate-button {
  padding: 12px 25px;
  font-size: 1.1rem;
  background-color: #007bff; /* Primary button color */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  line-height: normal;
  height: auto;
  flex-grow: 1; /* Allow button to grow */
  text-align: center; /* Center text within button */
  box-sizing: border-box; /* Include padding in width calculation */
}

.generate-button:hover {
  background-color: #0056b3; /* Darker shade on hover */
}

/* Styles for Custom Prompt Dropdown */
.custom-prompt-dropdown {
  padding: 12px 15px;
  font-size: 1.1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  height: auto;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg fill="white" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px center;
  padding-right: 40px;
  flex-grow: 1; /* Allow dropdown to grow */
  text-align: left; /* Align text left, adjust as needed */
  box-sizing: border-box; /* Include padding in width calculation */
}

.custom-prompt-dropdown:hover {
  background-color: #0056b3; /* Match button hover */
}

.custom-prompt-dropdown:disabled {
  background-color: #555; /* Style for disabled state */
  cursor: not-allowed;
}

/* Styles for Generated Prompt Area */
.generated-prompt {
  margin-top: 30px;
  padding: 20px;
  background-color: #2a2a2a; /* Darker background for prompt area */
  border-radius: 5px;
}

.generated-prompt h2 {
  margin-top: 0;
  margin-bottom: 15px;
  color: #eee;
}

.prompt-box {
  background-color: #333; /* Background for the text itself */
  padding: 15px;
  border-radius: 4px;
  border: 1px solid #444;
  color: #f0f0f0;
  font-family: monospace; /* Monospace font for prompt */
  white-space: pre-wrap; /* Preserve whitespace and wrap */
  word-wrap: break-word;
  min-height: 100px; /* Minimum height */
  margin-bottom: 15px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .properties-container {
    display: flex !important;
    flex-direction: column !important;
    gap: 20px;
    grid-template-columns: none;
  }
  .slider-group {
    width: 100%;
    box-sizing: border-box;
  }
  .action-row {
     flex-direction: column;
     align-items: stretch; /* Make items take full width */
     max-width: 90%; /* Adjust max-width for smaller screens */
  }
  .generate-button, .custom-prompt-dropdown {
     width: 100%; /* Make button/dropdown full width in column */
     text-align: center;
  }
  .custom-prompt-dropdown {
     padding-right: 15px;
     background-position: right 10px center; /* Adjust arrow position */
  }
}
